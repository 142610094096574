import React from 'react';

import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { Content } from '../components/content';

export default function() {
  const title = 'Rellenos oseos';

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>
      <Content>
        <p>
          Cuando los huesos maxilares han perdido volumen debido a la reabsorción sufrida, el cirujano máxilofacial con
          el fin de recuperar el continente perdido puede realizar injertos o rellenos óseos.
        </p>
        <p>
          Generalmente este procedimiento es utilizado previo a la colocación de implantes, o en traumatología o
          periodoncia, donde muchas veces el defecto óseo dificulta la estabilidad de las piezas y/o la estética. En
          estos casos los rellenos son utilizados para completar estos espacios favoreciendo la funcionalidad o como
          paso previo indispensable a algún tratamiento. Ello sólo será posible si existe la oseointegración, que es el
          proceso en que el hueso injertado integra naturalmente al hueso receptor. Este es un procedimiento que dura
          algunos meses y por lo general varía de paciente a paciente. Existen diferentes tipos de rellenos óseos, a
          saber: Bovino (proveniente de animal), Hidroxiapatita (de coral) Autógeno (del propio paciente)
        </p>
        <h2>Preguntas frecuentes</h2>
        <h3>¿Que tipo de anestesia se usa para rellenos óseos?</h3>
        <p>Normalmente la cirugía es ambulatoria, por lo que se requiere únicamente anestesia local.</p>
        <h3>¿Cuánto tiempo tengo que esperar después de un relleno óseo para la colocación de un implante?</h3>
        <p>
          Esto depende del material utilizado y del hueso receptor. Es un proceso que puede demorar entre 6-8 meses y en
          casos particulares hasta un año.
        </p>
      </Content>
    </Layout>
  );
}
